export const getImageWidth = (url: string): string => {
  const dimensionString = url.split('/')[5]
  const [width] = dimensionString.split('x')
  return parseInt(width) + 'px'
}

export const getImageHeight = (url: string): string => {
  const dimensionString = url.split('/')[5]
  const [, height] = dimensionString.split('x')
  return parseInt(height) + 'px'
}

export const getAspectRatio = (url: string): number => {
  const dimensionString = url.split('/')[5]
  const [width, height] = dimensionString.split('x').map(Number)
  const aspectRatio = width / height
  return aspectRatio
}

export const getImageName = (url: string): string => {
  const nameWithExtension = url.split('/')[7]
  const name = nameWithExtension.split('.')[0]
  return name
}
